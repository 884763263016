.App {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.linkLogo {
  text-decoration: none;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 22px;
  display: flex;
}

.headerLangue {
  float: right;
  /* width: 95%; */
}

.headerRight {
  width: 100%;
}

.my-div {
  float: right;
  text-align: right;
  margin-top: 30px;
  background-color: aqua;
}

.headerLangue ul {
  list-style: none;
  /* Loại bỏ các dấu đầu dòng */
  display: flex;
  margin-top: 10px;
  text-align: right;

}

/* Định dạng các mục danh sách (list items) bên trong danh sách UL */
.headerLangue ul li {
  padding: 10px;
  cursor: pointer;
  color: blue;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-align: right;
  float: right;
  width: auto;
  /* Khoảng cách giữa các mục danh sách */
}

.headerLangue ul li:hover {
  background-color: gray;
  color: white;
}

.textAbout {
  font-weight: bold;
  font-size: 1.75rem;
  color: blue;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 230px;
}

.txtAbout {
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

th,
td {
  border: 1px solid black;
  padding: 6px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}



/* Add some CSS for table responsiveness on smaller screens */
@media (max-width: 600px) {
  table {
    font-size: 14px;
  }
}

.txtTitleProduct {
  font-size: 20px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 20px;
  margin-bottom: 30px;
}


.linkLogo-mobile {
  text-decoration: none;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 18px;
  display: flex;
}

.headerLangue-mobile {
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.headerLangue-mobile ul {
  list-style: none;
  /* Loại bỏ các dấu đầu dòng */
  display: flex;
  margin-top: 5px;
  width: 100%;
  left: 0;
  padding: 0;
}

.headerLangue-mobile ul>li {
  padding: 5px;
  cursor: pointer;
  color: blue;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  width: auto;
  font-size: 12px;
  /* Khoảng cách giữa các mục danh sách */
}

.headerLangue-mobile ul li:hover {
  background-color: gray;
  color: white;
}

.textAbout-mobile {
  font-weight: bold;
  font-size: 1.3rem;
  color: blue;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 130px;
}

.imagemap {
  margin-top: -605px;
  text-align: right;
}

.imagemap img {
  width: 350px;
  height: 450px;
}

.imagemap-mobile {
  margin-top: -605px;
  text-align: right;
}

.imagemap-mobile img {
  width: 180px;
  height: 250px;
}

.admin-page {
  margin-left: 180px;
  padding: 0;
}





